import axios from 'axios';

// Função para enviar o token
export async function sendToken(username) {
    try {
        // Fazendo requisição para o servidor de frontend, que redireciona para o backend
        const response = await axios.post('https://vale.orbita.oficina.ci/api/send_token/', { username });
        console.log('Resposta completa do backend:', response.data);

        if (response.data && response.data.detail === 'Código enviado com sucesso.') {
            return response.data;
        } else {
            throw new Error(response.data?.message || 'Resposta da API em formato inesperado.');
        }
    } catch (error) {
        console.error('Erro ao enviar token:', error);

        if (error.response) {
            throw new Error(error.response.data?.detail || 'Erro na requisição.');
        } else if (error.request) {
            throw new Error('Sem resposta do servidor.');
        } else {
            throw new Error('Erro ao configurar a requisição.');
        }
    }
}

// Função para verificar o token
export async function verifyToken(token) {
    try {
        // Fazendo requisição para o servidor de frontend, que redireciona para o backend
        const response = await axios.post('https://vale.orbita.oficina.ci/api/verify_token/', { token });
        console.log('Resposta do backend:', response.data);
        return response.data;
    } catch (error) {
        console.error('Erro ao verificar o token:', error);
        throw new Error('Falha ao verificar o token.');
    }
}
