import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import '../styles/Login.css';
import desktopBackground from '../images/plano-de-fundo.png';
import mobileBackground from '../images/mobile.png';
import { sendToken } from '../services/auth'; // Função que envia o token
import logMessage from '../logger'; // Função de logging
import vale from '../images/vale.png'; // Logo Vale
import oficina from '../images/oficina.png'; // Logo Oficina

const Login = () => {
  const [username, setUsername] = useState(''); // Estado para armazenar o nome de usuário
  const [loading, setLoading] = useState(false); // Estado para controlar o carregamento do botão
  const [error, setError] = useState(''); // Estado para mensagens de erro
  const [message, setMessage] = useState(''); // Estado para mensagens de sucesso
  const navigate = useNavigate(); // Hook para navegação

  const backgroundImage = isMobile || isTablet ? mobileBackground : desktopBackground;

  // Função para lidar com o envio do formulário
  const handleSubmit = async (e) => {
    e.preventDefault(); // Previne o comportamento padrão do formulário
    setLoading(true); // Ativa o carregamento do botão
    setError(''); // Limpa erros anteriores
    setMessage(''); // Limpa mensagens anteriores

    // Verifica se o nome de usuário foi informado
    if (!username) {
      setError('Por favor, insira um nome de usuário.');
      setLoading(false); // Desativa o carregamento
      return;
    }

    logMessage('Iniciando envio do token', 'info', { username });

    try {
      // Chama a API para enviar o token de verificação
      const response = await sendToken(username);

      // Verifica se o envio do código foi bem-sucedido
      if (response && response.detail === 'Código enviado com sucesso.') {
        logMessage('Código enviado com sucesso', 'info', { username });
        setMessage(response.detail); // Exibe a mensagem de sucesso
        localStorage.setItem('username', username); // Armazena o nome do usuário no localStorage
        navigate('/verify-token'); // Redireciona para a tela de verificação de token
      } else {
        const apiErrorMessage = response?.message || 'Erro na resposta da API';
        setError(apiErrorMessage); // Exibe a mensagem de erro
        logMessage('Falha ao enviar código', 'warn', { response });
      }
    } catch (error) {
      logMessage('Erro ao enviar o código', 'error', { error });
      setError('Erro ao enviar o código. Por favor, tente novamente.'); // Exibe mensagem de erro
    } finally {
      setLoading(false); // Desativa o carregamento
    }
  };

  return (
    <div className="login-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="login-box">
        <h2>Bem-vindo</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)} // Atualiza o estado do nome de usuário
              required
              placeholder="Nome de usuário"
            />
          </div>
          <button type="submit" className="login-button" disabled={loading}>
            {loading ? 'Enviando...' : 'Enviar Código'}
          </button>
          {error && <p className="error-message">{error}</p>}
          {message && <p className="success-message">{message}</p>}
        </form>
      </div>
      <div className="logo-images">
        <img src={vale} alt="Vale" className="logo-image" />
        <img src={oficina} alt="Oficina" className="logo-image" />
      </div>
    </div>
  );
};

export default Login;
