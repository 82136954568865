import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import '../styles/Login.css';
import desktopBackground from '../images/plano-de-fundo.png';
import mobileBackground from '../images/mobile.png';
import { verifyToken } from '../services/auth';
import vale from '../images/vale.png'; // Importa a imagem vale
import oficina from '../images/oficina.png'; // Importa a imagem oficina

const TokenVerification = () => {
    const [token, setToken] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [timeLeft, setTimeLeft] = useState(300); // 5 minutos em segundos
    const navigate = useNavigate();

    const backgroundImage = isMobile || isTablet ? mobileBackground : desktopBackground;

    useEffect(() => {
        if (timeLeft === 0) {
            alert('Token expirado. Por favor, solicite um novo.');
            navigate('/'); // Redireciona para a página de login
        }

        const timer = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        // Redireciona para a tela de login após 5 minutos de inatividade
        const inactivityTimeout = setTimeout(() => {
            alert('Tempo esgotado. Redirecionando para a tela de login.');
            navigate('/');
        }, 300000); // 300000 ms = 5 minutos

        return () => {
            clearInterval(timer); // Limpa o timer ao desmontar o componente
            clearTimeout(inactivityTimeout); // Limpa o timeout de inatividade
        };
    }, [timeLeft, navigate]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await verifyToken(token);

            if (response.success) {
                localStorage.setItem('token', response.token);
                localStorage.setItem('userRole', response.userRole);
                if (response.userRole === 'admin') {
                    navigate('/admin');
                } else {
                    navigate('/powerbi');
                }
            } else {
                setError('Código inválido. Por favor, tente novamente.');
            }

        } catch (err) {
            setError('Erro ao verificar o código. Por favor, tente novamente.');
            console.error('Erro na verificação do token:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div 
            className="login-container" 
            style={{ 
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}
        >
            <div className="login-box">
                <h2>Verificar Código</h2>
                <p>Tempo restante: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</p>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <input
                            type="text"
                            value={token}
                            onChange={(e) => setToken(e.target.value)}
                            required
                            placeholder="Digite o código recebido"
                        />
                    </div>
                    <button type="submit" className="login-button" disabled={loading}>
                        {loading ? 'Verificando...' : 'Verificar Código'}
                    </button>
                    {error && <p className="error-message">{error}</p>}
                </form>
            </div>
            <div className="logo-images">
                <img src={vale} alt="Vale" className="logo-image" />
                <img src={oficina} alt="Oficina" className="logo-image" />
            </div>
        </div>
    );
};

export default TokenVerification;
