import log from 'loglevel';

// Definindo o nível de log
log.setLevel('info');  // Nível de log: 'trace', 'debug', 'info', 'warn', 'error'

// Função de log personalizada
const logMessage = (message, level = 'info', additionalData = {}) => {
  log[level](message, additionalData);
};

export default logMessage;
