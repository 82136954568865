import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './PowerBI.css';
import axios from 'axios';

const PowerBI = () => {
    const [showLogout, setShowLogout] = useState(false);
    const [reportUrl, setReportUrl] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    useEffect(() => {
        if (!username) {
            navigate('/');
        } else {
            const fetchReportUrl = async () => {
                try {
                    const response = await axios.get('/api/powerbi-report/');
                    console.log('Resposta completa da API:', response.data);

                    if (response.data && response.data.content) {
                        // Extrair a URL do relatório do conteúdo HTML
                        const parser = new DOMParser();
                        const htmlDoc = parser.parseFromString(response.data.content, 'text/html');
                        const iframeElement = htmlDoc.querySelector('iframe');
                        
                        if (iframeElement && iframeElement.src) {
                            setReportUrl(iframeElement.src);
                        } else {
                            throw new Error('URL do relatório não encontrada no conteúdo HTML.');
                        }
                    } else {
                        throw new Error('Conteúdo do relatório não encontrado ou estrutura de resposta inesperada.');
                    }
                } catch (error) {
                    console.error('Erro ao carregar a URL do relatório:', error);
                    setError('Não foi possível carregar o relatório. Tente novamente mais tarde.');
                } finally {
                    setLoading(false);
                }
            };

            fetchReportUrl();
        }
    }, [username, navigate]);

    const handleLogout = () => {
        localStorage.removeItem('username');
        setShowLogout(false);
        navigate('/');
    };

    return (
        <div className="powerbi-page-container">
            <div className="sidebar">
                <div className="user-info" onClick={() => setShowLogout(!showLogout)}>
                    <span className="username">{username}</span>
                    <span className="arrow">▼</span>
                </div>
                {showLogout && (
                    <div className="logout-menu">
                        <button className="logout-button" onClick={handleLogout}>
                            Sair
                        </button>
                    </div>
                )}
            </div>

            <div className="iframe-container">
                {loading && <p>Carregando relatório...</p>}
                {error && <p className="error-message">{error}</p>}
                {reportUrl && !error && (
                    <iframe
                        title="Power BI Report"
                        src={reportUrl}
                        frameBorder="0"
                        allowFullScreen
                        style={{ width: '100%', height: '100%' }}
                        loading="lazy"
                    ></iframe>
                )}
            </div>
        </div>
    );
};

export default PowerBI;
